/* PROGRESSBAR ----------------------------------------------- */

.progressBar {
  background: var(--colors-grey900);
  height: 5px;
  border-radius: 8px;
  overflow: hidden;
}

.progressBar:after {
  content: "";
  background-image: linear-gradient(140deg,
      transparent 0%,
      transparent 40%,
      white 41%,
      white 60%,
      transparent 60%,
      transparent 61%,
      transparent 100%);
  animation: progress-bar-stripes 1.5s infinite;
  width: 100%;
  height: 100%;
  display: block;
  left: -100%;
  position: relative;
  opacity: 0.4;
}

.progressBar_content {
  background-color: var(--colors-green);
  background-image: linear-gradient(45deg,
      transparent,
      var(--colors-admin-selected));
  height: 100%;
  width: 0;
  position: relative;
  overflow: hidden;
  border-radius: inherit;
}

.progressBar_content:after {
  content: "";
  background-image: linear-gradient(90deg,
      white 0%,
      white 25%,
      transparent 25%,
      transparent 75%,
      white 75%,
      white 99%,
      transparent 100%);
  animation: progress-bar-stripes 1s infinite linear;
  left: -100%;
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  opacity: 0.3;
  border-radius: inherit;
}

@keyframes progress-bar-stripes {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(200%);
  }
}