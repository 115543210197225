/* Basic -------------------------------- */

._ignoreTouch {
  pointer-events: none;
}
._uppercase {
  text-transform: uppercase;
}
._hideSelect {
  user-select: none;
}
._centerText {
  text-align: center;
}
._relative {
  position: relative;
}
._absolute {
  position: absolute;
}
._hidden {
  display: none;
}

/* Colors -------------------------------- */

._color1 {
  background-color: aliceblue;
}
._color2 {
  background-color: aquamarine;
}
._color3 {
  background-color: bisque;
}
._color4 {
  background-color: tomato;
}

._o25 {
  opacity: 0.25;
}

._o50 {
  opacity: 0.5;
}

._o75 {
  opacity: 0.5;
}

/* Text ---------------------------------- */

._capitalize {
  text-transform: capitalize;
}

/* Flex ---------------------------------- */

._vFlip {
  transform: rotateX(180deg);
}

._hFlip {
  transform: rotateY(180deg);
}

._turncw {
  transform: rotate(90deg);
}
._turnacw {
  transform: rotate(-90deg);
}

/* Space -------------------------------- */

._mr0 {
  margin-right: 0px;
}
._mb0 {
  margin-bottom: 0px;
}
._ml0 {
  margin-left: 0px;
}
._mt0 {
  margin-top: 0px;
}
._mr5 {
  margin-right: 5px;
}
._mb5 {
  margin-bottom: 5px;
}
._ml5 {
  margin-left: 5px;
}
._mt5 {
  margin-top: 5px;
}
._mr10 {
  margin-right: 10px;
}
._mb10 {
  margin-bottom: 10px;
}
._ml10 {
  margin-left: 10px;
}
._mt10 {
  margin-top: 10px;
}
._mr15 {
  margin-right: 15px;
}
._mb15 {
  margin-bottom: 15px;
}
._ml15 {
  margin-left: 15px;
}
._mt15 {
  margin-top: 15px;
}
._mr20 {
  margin-right: 20px;
}
._mb20 {
  margin-bottom: 20px;
}
._ml20 {
  margin-left: 20px;
}
._mt20 {
  margin-top: 20px;
}
/* Flex -------------------------------- */
/* https://codyhouse._co/ds/docs/framework/util-flexbox */

._flex {
  display: flex;
}
._inline-flex {
  display: inline-flex;
}
._flex-wrap {
  flex-wrap: wrap;
}
._flex-nowrap {
  flex-wrap: nowrap;
}
._flex-column {
  flex-direction: column;
}
._flex-column-reverse {
  flex-direction: column-reverse;
}
._flex-row {
  flex-direction: row;
}
._flex-row-reverse {
  flex-direction: row-reverse;
}
._flex-center {
  align-items: center;
}
._flex-start {
  align-items: flex-start;
}
._flex-end {
  align-items: flex-end;
}
._flex-spaceBetween {
  justify-content: space-between;
}
._flex-jcenter {
  justify-content: center;
}
._flex-one {
  flex: 1;
}

/* Icon fix */

._top1 {
  position: relative;
  top: 1px;
}

._top2 {
  position: relative;
  top: 2px;
}

._top3 {
  position: relative;
  top: 3px;
}
