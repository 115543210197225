/* HOMEPAGE---------------------------------------------------------- */

/* HEADER & NAVIGATION ---------------------------------------------- */

.logo {
  display: flex;
  align-items: center;
}

.logo a {
  text-decoration: none;
}

.logo-text {
  display: none;
  color: var(--colors-white);
  margin-left: 12px;
  font-size: 2rem;
  margin-right: 10px;
}

.logo-beta {
  display: none;
  background: linear-gradient(90deg, #ffa55a 0%, #fe8a8b 100%);
  border-radius: 3px;
  color: var(--colors-white);
  font-size: 1rem;
  padding: 2px 5px;
}

.logo--withText .logo-text {
  display: block;
}

.logo--withBeta .logo-beta {
  display: block;
}

.logo--size-sm svg {
  width: 45px;
}

.nav {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  flex: 1;
}

.nav-auth {
  display: none;
}

.nav-auth--mobile {
  display: grid;
  grid-gap: 12px;
}

.nav--desktop {
  display: none;
}

.nav--mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  list-style: none;
  margin-top: 20px;
}

.nav--mobile li {
  width: 100%;
}

.nav--mobile a,
.nav-auth--mobile a {
  text-decoration: none;
  background: var(--colors-grey800);
  padding: 12px 12px;
  display: block;
  width: 100%;
  margin-bottom: 10px;
  border-radius: var(--border-radius-sm);
}

.nav-auth--mobile a {
  background-color: var(--colors-primary);
}

.nav--desktop .nav_item--home {
  display: none;
}

.header_logo svg {
  position: relative;
  top: -1px;
}

.header_mobileNavButton {
  cursor: pointer;
  width: 30px;
  height: 30px;
  stroke: var(--colors-white);
}

.header_mobileNav {
  display: block;
  background: #061c2c;
  position: absolute;
  top: 80px;
  width: 100vw;
  height: 100vh;
  left: -25px;
  padding: 25px 25px;
  border-top: 1px solid var(--colors-grey800);
  z-index: 1111;
}

@media (min-width: 768px) {
  .header_logo {
    margin-right: 40px;
    position: relative;
    top: -1px;
  }
  .header_logInButton {
    margin-left: 10px;
  }
  .header_mobileNavButton {
    display: none;
  }
  .header_logInButton {
    display: grid;
  }
  .nav--desktop {
    display: flex;
  }
  .nav_item {
    display: block;
    list-style: none;
    margin-right: 6px;
  }
  .nav-auth {
    display: block;
  }
  .nav_item a {
    color: var(--colors-white);
    text-decoration: none;
    font-weight: 500;
    background-color: var(--colors-secondary);
    padding: 4px 12px;
    border-radius: 100px;
    margin-right: 4px;
  }
  .nav_item a.active {
    background: var(--colors-white);
    color: var(--colors-black);
  }
  .header_mobileNav {
    display: none;
  }
}

/* PAGES ------------------------------------------------------------ */

.page {
  color: var(--colors-grey400);
  background-color: var(--colors-grey900);
}

.page--blurred:after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(128px);
  -webkit-backdrop-filter: blur(128px);
}

.page--home {
  background: url("@assets/images/bg_homepage.jpg") 0% 0% no-repeat;
  background-size: 300%;
}

.page--auth {
  background: url("@assets/images/bg_homepage.jpg") 0% 0% no-repeat;
  background-size: 300%;
}

@media (min-width: 768px) {
  .page--home {
    background-size: 125%;
  }
}

/* Page Content */

.page-content {
  max-width: 1100px;
  margin: 0 auto;
  min-height: 100vh;
  padding: 0 25px;
  z-index: 10;
  position: relative;
}

@media (min-width: 768px) {
  .page-content {
    padding: 0 30px;
  }
}

.header {
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

canvas {
  position: absolute;
}

/* HOMEPAGE-SECTIONS ------------------------------------------------

  - Hero     .hero
  - Features .features
  - Clients  .clients
  - Faqs     .faqs

*/

.hero,
.features,
.faq {
  padding-bottom: 20px;
}

.hero {
  display: grid;
  grid-gap: 20px;
  padding: 62px 0;
  align-items: center;
}

.hero-right {
  margin-top: 40px;
  padding: 5px;
  background: linear-gradient(45deg, #ffa25c, #f5567c);
  border-radius: var(--border-radius-md);
}

.hero-h1 {
  background: linear-gradient(45deg, #ffac4a, #ff74af);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0 0 10px 0;
}

.hero-p {
  font-size: 2rem;
  margin: 0 0 10px 0;
  line-height: 1.25;
}

.hero-buttons {
  display: flex;
  flex-direction: column;
}

.hero-buttons a {
  flex: 1;
  text-align: center;
}

.hero-quickStartEmail {
  background: #1f7acb;
  display: block;
}

@media (min-width: 768px) {
  .hero {
    grid-gap: 40px;
    padding: 100px 0 162px 0;
    grid-template-columns: repeat(12, minmax(auto, 1fr));
  }
  .hero-h1 {
    font-size: 4.8rem;
  }
  .hero-left {
    grid-column-start: span 6;
  }
  .hero-right {
    grid-column-start: 7;
    grid-column-end: 13;
    display: flex;
    margin-top: 50px;
  }
  .features,
  .setup,
  .faq {
    padding-bottom: 80px;
  }
  .hero-buttons {
    display: block;
  }
  .hero-buttons a {
    margin-right: 10px;
  }
  .hero-quickStartEmail {
    display: none;
  }
  .hero-terminal {
    height: 260px;
  }
}

.intro {
  margin: 110px 0;
  text-align: center;
  max-width: 600px;
  justify-self: center;
}

.intro-h2 {
  font-size: 1.6rem;
  margin: 0;
  text-transform: uppercase;
  font-weight: 400;
}

.intro-h3 {
  font-size: 4rem;
  margin: 1.5rem 0;
  color: var(--colors-white);
  font-weight: 700;
}

.intro-p {
  font-size: 1.8rem;
}

@media (min-width: 768px) {
  .intro-h3 {
    font-size: 5rem;
  }
}

.features-headline {
  color: var(--colors-white);
}

.featureList {
  padding-left: 15px;
}

.features .emp-border {
  border: 1px solid #ffffff30;
  border-radius: 6px;
  padding: 8px 13px;
  display: block;
  background: #00000026;
}

.featureList-cloud1 {
  background: url("@assets/images/cloud1.png") right 0% no-repeat;
  background-size: 380px;
}

.featureList-cloud2 {
  background: url("@assets/images/cloud2.png") left 0% no-repeat;
  background-size: 380px;
}

@media (min-width: 768px) {
  .featureList-wrap {
    padding-left: 25px;
  }
  .featureList-cloud2 {
    min-height: 330px;
  }
}

.clients {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 100px;
  grid-gap: 12px;
}

.clients_client {
  background: var(--colors-secondary);
  border-radius: var(--border-radius-lg);
  padding: 12px;
}

.faqs {
  padding: 0;
}

.faq {
  list-style: none;
  position: relative;
  padding: 10px 0px 15px;
}

.faq:not(:last-child) {
  border-bottom: 1px solid var(--colors-secondary);
}

.faq_title {
  font-weight: 600;
  cursor: pointer;
  line-height: 1.4;
  position: relative;
}

.faq_title::before {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 100px;
  background-color: var(--colors-primary);
  left: -12px;
  top: 7px;
  transform: translateY(50%);
}

.faq.selected .faq_title::before {
  background-color: var(--colors-green);
}

.faq_text {
  height: 0;
  overflow: hidden;
}

/* FOOTER ----------------------------------------------------------- */

.footer {
  margin-top: 150px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column-reverse;
}

.footer-logo {
  display: block;
  margin-bottom: 10px;
}

.footer-externalLink {
  position: relative;
  top: 4px;
  margin-left: 5px;
  stroke: currentColor;
  width: 15px;
}

.footerSection-header {
  margin-left: -9px;
  background: #ffffff12;
  margin-bottom: 4px;
  padding: 2px 2px 2px 8px;
  border-radius: 3px;
}

.footerSections:before {
  content: "ShipThis Pages";
  margin-bottom: 14px;
  display: block;
  color: #ffffff;
}

.footerSection {
  list-style: none;
  padding: 0 0 16px 8px;
  margin: 0;
}

.footerSection a {
  color: var(--colors-grey400);
  text-decoration: none;
  font-size: 1.4rem;
  padding: 4px 0px;
  display: inline-block;
}

.footerSection a:hover {
  background: #ffffffad;
  border-radius: 3px;
  margin-left: -8px;
  padding-left: 8px;
  background: #ffffff12;
  color: var(--colors-white);
  width: calc(100% + 8px);
  display: inline-block;
}

.footer-copy {
  line-height: 1.4;
  font-size: 1.4rem;
  margin-top: 19px;
  color: var(--colors-grey400);
}

@media (min-width: 768px) {
  .footer {
    display: grid;
    grid: 1fr / 200px 1fr;
  }
  .footerSections {
    display: flex;
  }
  .footerSection {
    width: 33%;
    margin-top: 0px;
    border-bottom: 0px;
    padding-bottom: 0px;
    padding-left: 25px;
    margin-right: 10px;
  }
  .footerSections:before {
    content: none;
  }
}

/* AUTHENTICATION --------------------------------------------------- */

.page-content--auth {
  display: grid;
  align-items: center;
  text-align: center;
}

.auth {
  display: grid;
  grid-template-rows: repeat(4, auto);
  grid-auto-rows: 40px 40px 40px;
  width: 320px;
  margin: 0 auto;
  grid-gap: 12px;
  border: 1px solid hsl(from var(--colors-grey700) h s l / 0.4);
  padding: 24px;
  border-radius: var(--border-radius-lg);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.0784314) 0%,
    rgba(153, 153, 153, 0.0784314) 100%
  );
  position: relative;
}

.auth--wider {
  max-width: 414px;
  width: auto;
}

.auth_error {
  margin-bottom: -14px;
}

.auth_h1 {
  font-size: 2rem;
  margin: 0;
}

.auth_h2 {
  font-size: 1.4rem;
  font-weight: 700;
  text-align: left;
  margin-bottom: 8px;
}

.auth_badge {
  background: rgb(0 0 0 / 30%);
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 6px;
  font-size: 1.6rem;
  color: #ffffff;
  padding: 6px 8px;
  font-weight: 400;
  position: absolute;
  right: 20px;
  top: 12px;
}

.auth_p {
  line-height: 1.3;
  font-size: 1.4rem;
  text-align: left;
  color: var(--colors-grey400);
}

.auth_p a {
  color: var(--colors-link);
}

.auth_input {
  padding: 12px 15px;
  border-radius: 8px;
  border: 0;
}

.auth-checkbox {
  background: var(--colors-white);
  width: 20px;
  height: 20px;
  border-radius: var(--border-radius-sm);
  margin: 0;
}

.auth_footer {
  max-width: 320px;
  margin: 0 auto;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  font-size: 1.4rem;
}

.auth_footer--wider {
  max-width: 414px;
  width: auto;
}

.auth_footer_links a {
  margin-right: 12px;
  text-decoration: none;
  opacity: 0.5;
}

.auth_footer_links a:hover {
  opacity: 1;
  transition: opacity 0.2s;
}

.auth_features {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: 6px;
  font-size: 1.2rem;
  line-height: 1.3;
}

.auth_features li {
  color: var(--colors-admin-selected);
  background-color: #00000070;
  border-radius: var(--border-radius);
  padding: 8px 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

/* PriceList */

.price-intro {
  margin: 64px 0;
  text-align: center;
  font-size: 2rem;
}

.price-intro h2 {
  color: var(--colors-white);
  font-size: 3rem;
}

.prices {
  display: grid;
  gap: 12px;
  margin: 0 auto;
}
.prices-tier:nth-child(1) {
}
.prices-tier:nth-child(2) {
}
.prices-tier:nth-child(3) {
}

.prices-tier {
  background: rgb(255 255 255 / 0%);
  padding: 24px 18px 18px 18px;
  color: var(--colors-white);
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 15%);
  border-radius: var(--border-radius-md);
  position: relative;
  font-size: 1.4rem;
  overflow: hidden;
}

.prices-tier:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0px;
  background: #49639b36;
  right: 0;
  z-index: -1;
}

.prices-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.prices-desc {
  color: var(--colors-grey400);
}

.prices-title {
  margin: 0;
  font-size: 1.4rem;
  font-weight: 500;
}

.prices-popular {
  background: #ffffff36;
  border-radius: 7px;
  color: #ffffffe3;
  font-size: 1.2rem;
  padding: 2px 8px;
  position: absolute;
  right: 18px;
  border: 1px solid #c2e8ff1a;
}

.prices-main {
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.prices-priceText {
  font-size: 2.2rem;
  font-weight: 400;
  margin-bottom: 12px;
}

.prices-features {
  margin: 0;
  padding: 18px 0 0 0;
  list-style-position: inside;
  margin-top: 18px;
  position: relative;
  color: var(--colors-grey400);
  list-style: none;
}

.prices-features li:before {
  content: "";
  background: url("@app/assets/images/check.svg") transparent 1px 2px no-repeat;
  width: 12px;
  height: 12px;
  display: inline-block;
  margin-right: 7px;
  background-size: 12px;
}

.prices-features:before {
  content: "";
  border-top: 1px solid var(--colors-grey700);
  position: absolute;
  top: 0px;
  left: -15px;
  right: -15px;
}

.price-outro {
  text-align: center;
  margin: 64px 0;
}

@media (min-width: 768px) {
  .price-intro {
    padding: 0px 16%;
  }
  .prices {
    display: grid;
    grid-template: 1fr / repeat(12, 1fr);
    gap: 12px;
    max-width: 850px;
  }
  .prices-tier:nth-child(1) {
    grid-column: 1 / span 4;
  }
  .prices-tier:nth-child(2) {
    grid-column: 5 / span 4;
  }
  .prices-tier:nth-child(3) {
    grid-column: 9 / span 4;
  }
  .price-outro {
    padding: 0px 16%;
  }
}

/* Terminal adjustment */

.terminal {
  font-size: 1.4rem;
  height: 230px;
}
