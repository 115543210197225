:root {
  --color-black: #2e2626;
  --color-white: #ffffff;
  --color-primary: #ffcaca;
  --color-primary-hover: #ffd8d8;
  --color-border: #e5e7eb;
  --border-radius-sm: 6px;
  --border-radius-md: 12px;
  --padding-sm: 6px;
  --padding-md: 12px;
  --padding-default: 16px;
  --padding-lg: 24px;
}

.feedback {
  position: fixed;
  border-radius: var(--border-radius-md);
  bottom: 25px;
  right: 25px;
  box-shadow: 0px 14px 34px rgba(0, 0, 0, 0.35);
  z-index: 1000;
  overflow: hidden;
  font-size: 1.4rem;
  min-width: 80px;
  min-height: 80px;
}

.feedback.isShowing {
  background: white;
}

.feedback-openButton {
  background: var(--color-primary);
  display: flex;
  width: 47px;
  height: 47px;
  border-radius: calc(var(--border-radius-lg));
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 1;
}

.feedback.isOpen .feedback-openButton {
  z-index: -1;
}

.feedback-content {
  width: 315px;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.feedback-page {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: var(--padding-lg) var(--padding-default) var(--padding-default)
    var(--padding-default);
}

.feedback-page:nth-of-type(2) {
  position: absolute;
  left: 100%;
  top: 0px;
}

.feedback-page:nth-of-type(3) {
  position: absolute;
  left: 200%;
  top: 0px;
}

.feedback-page:nth-of-type(4) {
  position: absolute;
  left: 300%;
  top: 0px;
}

.feedback-h1 {
  font-size: 1.43em;
  margin-top: -4px;
  margin-bottom: 6px;
  font-weight: 600;
}

.feedback-p {
  font-size: 1em;
  margin-top: 2px;
}

.feedback-input {
  margin-top: var(--padding-md);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-border);
  padding: var(--border-radius-md);
  width: 100%;
}

.feedback-input:focus {
  outline: none;
  border-color: var(--color-black);
}

.feedback-input::placeholder {
  color: var(--color-black);
  opacity: 0.3;
}

.feedback-input-error {
  position: absolute;
  right: 12px;
  bottom: 12px;
  color: #ffffff;
  background: #ff5656;
  font-size: 1.2rem;
  padding: 2px 8px;
  border-radius: 5px;
}

.feedback-checkbox {
  appearance: none;
  background: var(--color-primary);
  width: 20px;
  height: 20px;
  border-radius: var(--border-radius-sm);
  margin: 0;
}

.feedback-checkbox:checked {
  background-image: url('data:image/svg+xml,<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 3L4.5 8.5L2 6" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  background-repeat: no-repeat;
  background-position: center;
}

.feedback-tags {
  display: flex;
  padding: var(--padding-md) 0;
  align-items: center;
}

.feedback-tagLabel {
  flex: 1;
  font-weight: 600;
}

.feedback-tag {
  border-radius: var(--border-radius-sm);
  border: 1px solid #c6c6c6;
  padding: 4px 6px;
  font-size: 0.75em;
  text-decoration: none;
  font-weight: 500;
  transition: transform 0.1s;
}

.feedback-tag:not(.feedback-tag--isSelected) {
  background: var(--color-white);
  border-color: #c6c6c6;
  color: var(--color-black);
}

.feedback-tag--orange {
  background-color: #fff8ed;
  border-color: #ffe1bf;
  color: #fa7315;
}

.feedback-tag--green {
  background-color: hsl(from var(--colors-green) h s 97 / 1);
  border-color: hsl(from var(--colors-green) h s 75 / 1);
  color: hsl(from var(--colors-green) h s 35 / 1);
}

.feedback-tag--pink {
  background-color: #faedff;
  border-color: #fabfff;
  color: #a315fa;
}

.feedback-tag--red {
  background-color: hsl(from var(--colors-red) h s 97 / 1);
  border-color: hsl(from var(--colors-red) h s 75 / 1);
  color: hsl(from var(--colors-red) h s 50 / 1);
}

.feedback-tag:not(:last-of-type) {
  margin-right: 5px;
}

.feedback-submitButton {
  background-color: var(--color-black);
  flex: 1;
  color: var(--color-white);
  text-decoration: none;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: var(--border-radius-md);
  margin-right: 54px;
  font-size: 1.15em;
  height: 48px;
  transition: background 0.5s;
}

.feedback-closeButton {
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: var(--border-radius-md);
  width: 48px;
  height: 48px;
  background: var(--color-primary);
  position: absolute;
  right: var(--padding-default);
  bottom: var(--padding-default);
}

.feedback-backButton {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--color-black);
  margin-left: -6px;
}

.feedback-thanks {
  height: 80px;
  background: #ffffff;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  padding-left: 20px;
  padding-right: 80px;
  line-height: 1.1;
}

.feedback-thanksIcon {
  background: var(--colors-green);
  padding: 9px;
  border-radius: 6px;
}
