/* ADMIN ----------------------------------------------------------- */

.page--admin {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 70px 1fr;
  min-height: 100vh;
  height: 100%;
  overflow: hidden;
  color: var(--colors-white);
  background-color: hsl(from var(--colors-grey950) h s l / 0.5);
}

.admin-sidebar {
  position: absolute;
  padding: 20px 6px;
  background: var(--colors-admin-section--darker);
  border-right: 1px solid hsl(from var(--colors-grey700) h s l / 0.4);
  grid-row-start: 1;
  grid-row-end: 3;
  width: 200px;
  height: 100%;
  z-index: 100;
  display: flex;
  flex-direction: column;
}

@media (min-width: 576px) {
  .admin-sidebar {
    padding: 20px 12px;
  }
}

@media (min-width: 1024px) {
  .admin-sidebar {
    position: fixed;
    left: 0 !important;
  }
}

@media (max-width: 1024px) {
  .admin-overlay {
    position: absolute;
    background: #00000070;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 90;
    cursor: pointer;
  }
}

.admin-overlay span {
  width: 24px;
  height: 24px;
}

.admin-logo {
  max-width: 42px;
  margin-left: 8px;
}

.admin-nav {
  margin: 30px 0px;
  overflow-y: auto;
  font-size: 1.4rem;
  height: 100%;
  flex: 1;
}

.admin-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.admin-navLink {
  text-decoration: none;
  padding: 6px 10px;
  display: flex;
  align-items: center;
  color: #ffffffd9;
  border-radius: 6px;
}

.admin-navIconWrap {
  display: flex;
  width: 22px;
  margin-right: 5px;
}

.admin-navIcon {
  stroke: var(--colors-grey700);
}

.admin-navLink:hover {
  background-color: var(--colors-grey900);
  color: var(--colors-white);
}

.admin-navLink:hover svg {
  stroke: var(--colors-grey400);
}

.admin-navLink.active {
  background-color: var(--colors-admin-selected);
  color: var(--colors-admin-section);
}

.admin-navLink.active svg {
  stroke: var(--colors-admin-section);
}

.admin-navLink:hover .admin-navIcon {
  opacity: 1;
}

.commit,
.server {
  color: var(--colors-grey700);
  font-size: 1rem;
  line-height: 1.2;
  display: grid;
  grid: 1fr / auto 1fr;
  grid-gap: 5px;
  align-items: center;
}

.commit-icon {
  stroke: var(--colors-grey700);
}

.server-radius {
  stroke: var(--colors-grey700);
}

.admin-main {
  color: var(--colors-white);
  overflow-y: auto;
  grid-column: 2 / 2;
  grid-row: 1 / span 2;
  padding: 0px 12px 18px;
}

.admin-header {
  padding: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--colors-grey900);
  color: var(--colors-white);
}

.admin-toggleSidebar {
  stroke: var(--colors-grey700);
}

.admin-toggleSidebar:hover {
  stroke: var(--colors-grey300);
}

.admin-pageTitle {
  font-size: 2rem;
  font-weight: 400;
  margin: 0;
  margin-top: -1px;
}

.admin__content {
  padding: 24px;
  background: var(--colors-grey100);
  height: 100%;
}

.admin-section {
  background: var(--colors-admin-section);
  padding: 10px 13px;
  border-radius: var(--border-radius-lg);
  margin-bottom: 12px;
  border: 1px solid #dddddd12;
}

.admin-section--ghost {
  background: none;
  border: none;
  padding: 2px;
}

.admin-section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
  min-height: 43px;
}

.admin-section__title {
  margin-top: 0;
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 18px;
}

.admin-section__title--flat {
  margin-bottom: 0;
}

.admin-section p:first-child,
.admin-section h1:first-child,
.admin-section h2:first-child {
  margin-top: 0;
}

@media (min-width: 576px) {
  .admin-main {
    padding: 0px 24px 18px;
  }
}

@media (min-width: 1024px) {
  .page--admin {
    grid-template-columns: 200px 1fr;
  }
}

.admin__grid {
  grid-gap: 12px;
}

.admin__navLink--notifications {
  padding: 10px 7px 0px;
  position: relative;
  opacity: 0.4;
}

/* GAME LIST -------------------------------------------------------- */

.game {
  background: var(--colors-admin-section);
  padding: 12px;
  border-radius: var(--border-radius-lg);
  border: 1px solid #dddddd12;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 12px;
  color: var(--colors-white);
  text-decoration: none;
  font-size: 1.4rem;
  transition: background-color var(--hover-transition-time);
  position: relative;
}

.game:hover {
  background-color: var(--colors-admin-section--hover);
}

.game__grid {
  grid-gap: 6px;
  grid-template-columns: 1fr;
}

.game-link {
  color: var(--colors-white);
  text-decoration: none;
}

.game-details {
  opacity: 0.5;
  margin-bottom: 5px;
}

.game-avatar {
  font-size: 12px;
}

.game-shortName {
  background: var(--colors-secondary);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--colors-white);
  font-size: 10px;
  width: 30px;
}

.game-engine {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 14px;
  opacity: 0.5;
  font-size: 1.2rem;
}

@media (min-width: 768px) {
  .game__grid {
    grid-template-columns: 1fr 1fr;
  }
}

/* GAME OVERVIEW ---------------------------------------------------- */

.gameOverview {
}

.gameOverview-hStack {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 12px;
}

.gameOverview-header {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gameOverview-headerIcon {
  stroke: var(--colors-primary);
  margin-right: 7px;
}

.gameOverview-setup {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-gap: 6px;
  font-size: 1.4rem;
}

.gameOverview-osIcon {
  fill: var(--colors-grey600);
}

.gameOverview-setupItem {
  display: flex;
  border-radius: 3px;
  flex-direction: row;
  padding: 6px 7px;
  align-items: flex-start;
  font-size: 1.2rem;
  border: 1px solid hsl(from var(--colors-grey700) h calc(s + 25) l / 0.5);
  color: hsl(from var(--colors-grey700) h calc(s + 25) l / 0.5);
}

.gameOverview-setupItem--index {
  margin-right: 5px;
}

.gameOverview-setupItem--current {
  color: var(--colors-primary);
  border: 1px solid var(--colors-primary);
}

.gameOverview-setupItem--done {
  border: 1px solid hsl(from var(--colors-green) h s 80 / 1);
  color: hsl(from var(--colors-green) h s 80 / 1);
}

.gameOverview-setupItem--check {
  margin-left: auto;
  position: relative;
  top: 2px;
}

.gameOverview-setupItem--check > svg {
  stroke: currentColor;
}

.gameOverview-setupItem--advice {
  font-size: 1.4rem;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
}

.gameOverview-setupItem--adviceContent {
  padding: 12px 0;
}

/* GAME JOBS -------------------------------------------------------- */

.job,
.build {
  margin-bottom: 6px;
  border-radius: var(--border-radius-lg);
  background: var(--colors-admin-section);
  border: 1px solid #dddddd12;
  position: relative;
  box-shadow: inset 0px -2px 4px -2px #000000, 1px 1px 0px #000000;
  overflow: hidden;
}

.job-header,
.build-header {
  display: grid;
  grid-gap: 12px;
  font-size: 1.4rem;
  align-items: center;
  cursor: pointer;
  grid-template-columns: auto 1fr auto auto auto;
  padding: 10px 12px;
  transition: background-color var(--hover-transition-time);
}

.job-header:hover,
.build-header:hover {
  background-color: var(--colors-admin-section--hover);
}

.job-id {
  display: flex;
  background: hsl(-201 71% 40% / 1);
  border-radius: var(--border-radius);
  padding: 0px 6px;
  font-size: 1.4rem;
  align-items: center;
}

.job-name {
  opacity: 0.5;
}

.job-type {
  margin-left: 5px;
}

.job-status {
  display: flex;
  align-items: center;
}

.job-content {
  overflow: hidden;
  height: 0;
  padding: 0px 12px;
  display: flex;
  flex-direction: column;
}

.job-content--visible {
  height: auto;
}

@media (max-width: 576px) {
  .job-nameWrap {
    grid-row: 2;
    grid-column: span 4;
  }
  .job-timeLine {
    grid-column: span 3;
    justify-content: end;
  }
  .job-status {
    justify-content: end;
    grid-column: auto;
  }
  .job-updated {
    grid-row: 2;
    justify-content: end;
  }
}

/* GAME JOB LOG ----------------------------------------------------- */

.job-log {
  background: var(--colors-black);
  border-radius: 12px;
  font-family: var(--font-mono);
  font-size: 1.2rem;
  max-height: 250px;
  overflow-y: scroll;
  overflow-x: scroll;
  border: 12px solid var(--colors-black);
  transition: max-height 0.5s;
  /* Reverse Log */
  display: flex;
  flex-direction: column-reverse;
}

.job-log--noLogData {
  max-height: 50px;
}

.job-log--fullScreen {
  max-height: none;
  overflow-y: unset;
}

.job-log-spinner {
  position: absolute;
  right: 20px;
  top: 15px;
}

.job-log::-webkit-scrollbar {
  width: 3px;
}

.job-log::-webkit-scrollbar:horizontal {
  height: 3px;
}

.job-log::-webkit-scrollbar-thumb {
  background: var(--colors-grey800);
  border-radius: 12px;
}

.job-log::-webkit-scrollbar-thumb:hover {
  background: var(--colors-grey500);
}

.job-log::-webkit-scrollbar-corner {
  display: none;
}

.job-log-line {
  text-wrap: wrap;
  word-wrap: break-word;
  word-break: break-all;
  display: grid;
  grid-template-columns: max-content 60px 1fr;
  grid-gap: 0 10px;
  align-items: start;
}

.job-log-text {
  opacity: 0.8;
}

.job-log-stage {
  font-size: 0.8em;
  line-height: 1.8;
}

.job-log-stage--SETUP {
  color: #ffb3b3;
}
.job-log-stage--EXPORT {
  color: #fffacd;
}
.job-log-stage--CONFIGURE {
  color: #ffd9b3;
}
.job-log-stage--BUILD {
  color: #b3ffb3;
}
.job-log-stage--PUBLISH {
  color: #b3d9ff;
}

.job-log-hilight {
  color: #07b6d47d;
}

.job-log-line--error .job-log-hilight {
  color: var(--colors-red);
  color: hsl(from var(--colors-red) h s l / 0.7);
}

.job-footer {
  margin-top: 8px;
  display: grid;
  grid-template-columns: auto auto auto 1fr auto;
  grid-gap: 4px;
  margin-bottom: 8px;
}

.job-button {
  padding: 4px 16px;
  font-size: 1.4rem;
}

.steps {
  margin-top: 22px;
}

.step {
  display: grid;
  grid-template-columns: 60px 1fr;
  margin-bottom: 12px;
  padding: 12px;
  background: #ffffff;
  border-radius: var(--border-radius);
  color: var(--colors-black);
  grid-gap: 12px;
}

.step-marker {
  background: var(--colors-red);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--colors-white);
  width: 100%;
  height: 100%;
}

.step-todo {
  opacity: 0.5;
}

.step-credentialList {
  display: inline-flex;
  align-items: center;
  margin-top: 12px;
  background: var(--colors-grey100);
  padding: 12px;
  border-radius: var(--border-radius-lg);
}

/* GAME BUILDS ------------------------------------------------------ */

.build-header {
  grid-template-columns: auto 1fr auto auto auto 120px;
  color: var(--colors-white);
  text-decoration: none;
}

.build-id {
  display: flex;
  background: var(--colors-blue);
  border-radius: var(--border-radius);
  padding: 0px 6px;
  font-size: 1.4rem;
  align-items: center;
}

.build-name {
  opacity: 0.5;
}

.build-type {
  margin-left: 5px;
}

.build-job {
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 1px 4px;
  color: var(--colors-grey600);
  text-decoration: none;
}

.build-git {
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 2px 6px;
  color: #ffffff;
  text-decoration: none;
  border: 1px solid var(--colors-grey500);
  background-color: var(--colors-grey600);
  font-size: 1.2rem;
}

.build-downloadButton {
  position: absolute;
  right: 6px;
}

/* TERMS */

.privacy,
.terms {
  font-size: 1.4rem;
}

.terms h1 {
  font-size: 3.2rem;
}

.privacy h1,
.privacy h2,
.privacy h3,
.terms h1,
.terms h2,
.terms h3 {
  color: var(--colors-white);
}

/* GAME CREDENTIALS ------------------------------------------------- */

.userCredential, .projectCredential {
  margin-bottom: 10px;
}

.userCredential-os, .projectCredential-os {
  fill: var(--colors-grey600);
}

.userCredential-isActive, .projectCredential-isActive {
  background-color: var(--colors-green);
  font-size: 1.2rem;
  color: var(--colors-admin-section);
  padding: 3px 6px;
  border-radius: 3px;
}

.userCredential-text, .projectCredential-text {
  color: #ffffffcc;
  background: var(--colors-grey800);
  border-radius: 6px;
  padding: 8px 10px;
  flex: 1;
  font-size: 1.4rem;
}

.splash {
  overflow: hidden;
  position: relative;
}


.cli-command {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  padding: 8px
}

.cli-command__copy {
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}