/* FORM */

.formItem {
  display: inline-block;
  width: 100%;
}

.formItem-label {
  display: flex;
  font-size: 1.4rem;
  color: var(--colors-grey600);
  margin-bottom: 2px;
  justify-content: space-between;
  margin-bottom: 6px;
}

.formItem-label--clip {
  height: 1em;
  overflow: hidden;
  align-items: start;
  word-break: break-all;
}

.formItem-credentialIcon {
  background: var(--colors-green);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  padding: 3px 0;
}

.formItem-inputContainer {
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  font-size: 1.4rem;
}

.formItem-inputContainer--locked {
  cursor: pointer;
}

.formItem-input {
  outline: none;
  border: none;
  border-radius: 6px;
  padding: 8px 10px;
  width: 100%;
  transition: background-color 0.75s;
}

.formItem-select {
  outline: none;
  border: none;
  border-radius: 6px;
  padding: 8px 10px;
  width: 100%;
  text-transform: capitalize;
  appearance: none;
  transition: background-color 0.75s;
}

.formItem-inputContainer--dropdown:before {
  content: "";
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgOUwxMiAxNUwxOCA5IiBzdHJva2U9IiM2NDc1ODIiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
  position: absolute;
  right: 3px;
  width: 24px;
  height: 24px;
  top: 7px;
  background-size: 20px;
  background-repeat: no-repeat;
}

.formItem-input[disabled],
.formItem-select[disabled] {
  color: #ffffffcc;
  background: var(--colors-grey800);
  transition: background-color 0.35s;
  pointer-events: none;
}

.formItem-lock {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 8px 12px;
  cursor: pointer;
}

.formItem-lock--dropdown {
  right: 20px;
}

.formItem-updateButtons {
  position: absolute;
  top: 4px;
  right: 4px;
  display: flex;
  gap: 4px;
}

.formItem-updateValue {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 0px 6px;
  font-size: 1.2rem;
  height: 24px;
  /* box-shadow: 0px 2px 0px #bb4e68; */
}

.formItem-updateValue--update {
  background: var(--colors-primary);
}

.formItem-updateValue--cancel {
  background: var(--colors-black);
}

.formItem-updateValue:hover {
  cursor: pointer;
  background-color: var(--colors-primary--hover);
}

.formItem-input::placeholder {
  color: var(--colors-grey950);
}

.formItem-info {
  padding: 0px;
  border-radius: 6px;
  display: flex;
}

.formItem-info:not(:last-of-type) {
  margin-right: 4px;
}