/* SPINNER ---------------------------------------------------------- */

.spinner {
  transform-origin: center;
  animation: spinner 0.5s infinite linear;
  fill: var(--colors-admin-selected);
  opacity: 0.4;
}

.spinner--white {
  fill: var(--colors-white);
}

.spinner--black {
  fill: var(--colors-black);
}

@keyframes spinner {
  100% {
    transform: rotate(360deg);
  }
}