@import url("./homepage.css");
@import url("./admin.css");
@import url("./variables.css");
@import url("./form.css");
@import url("./terminal.css");
@import url("./toast.css");

/*
  ShipThis.cc Styles

  Media Queries:
  @media (min-width: 576px) { }
  @media (min-width: 768px) { }

  Mobile first.

  LA BEM

  .button {}            Block component
  .button--primary {}   Modifier
  .button-icon {}       Child Element within block
  .button-icon--md {}   Child modifier
  .button-icon--lg {}

  Z-Index
    1000: Feedback Widget
    2000: PopUp
*/

html {
  font-size: 62.5%;
  background: var(--colors-black);
}

body {
  margin: 0;
  font-family: var(--font-default), -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6em;
  font-optical-sizing: auto;
  overflow-x: hidden;
}

code {
  font-family: "Roboto Mono", monospace;
}

/* HELPERS --------------------------------------------------------- */

.lockScroll {
  overflow: hidden;
}

/* TYPE ------------------------------------------------------------ */

h1 {
  font-size: 4.2rem;
}

h2 {
  font-size: 2.4rem;
  font-weight: 600;
}

h3 {
  font-size: 1.8rem;
  font-weight: 400;
}
