/* Toast */

.toast-container {
  display: flex;
  flex-direction: row;
  position: fixed;
  z-index: 10000;
  bottom: 15px;
  width: 250px;
  flex-direction: column;
  right: 15px;
  color: black;
}

.toast {
  background: #ffffff;
  border-radius: 5px;
  padding: 14px 20px;
  border: 1px solid #909090;
  margin-bottom: 6px;
  overflow: hidden;
  position: relative;
}

.toast-hide {
  display: none;
  position: absolute;
  right: 10px;
  top: 12px;
  cursor: pointer;
}

.toast-hide svg {
  opacity: 0.3;
}

.toast-hide:hover svg {
  opacity: 1;
}

.toast:hover .toast-hide {
  display: block;
}

@media (max-width: 500px) {
  .toast-container {
    position: fixed;
    bottom: auto;
    top: 0px;
    width: 100%;
    left: 0;
    padding: 15px;
  }
}
